<template>
  <div>
    <div class="title">{{ article.title }}</div>
    <div class="published_time">
      发布时间 {{ dateFormat(article.published_time) }}
    </div>
    <div v-html="article.content"></div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from 'vue'
import axios from '@/libs/axios'
import { useRoute } from 'vue-router'

const route = useRoute()
const article = reactive({
  id: 0,
  titlle: '',
  content: '',
  publish_time: ''
})

const getArticle = async () => {
  const response = await axios.get('/articles/get', {
    params: { id: article.id }
  })
  Object.assign(article, response)
}

const dateFormat = (v) => new Date(v).toLocaleString()

onMounted(async () => {
  article.id = route.query.id
  getArticle()
})
</script>
<style lang="less" scoped>
.title {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 550;
  text-align: center;
}
.published_time {
  font-size: 14px;
  color: #999;
  margin-bottom: 20px;
  text-align: center;
}
</style>
